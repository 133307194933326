import { useTranslation } from "features/translations";
import { useContext, useEffect } from "react";
import {
  Action,
  Heading3,
  Icon,
  InputType as InputItem,
  Paragraph,
} from "shared/components";

import { FormContent } from "../Form.Provider";
import { FormSubmitStatus } from "../FormSubmitStatus";
import styles from "./FormRegistration.module.scss";

export const FormRegistration = ({ info }) => {
  const { t } = useTranslation();
  const {
    data,
    type,
    title,
    buttonText,
    fields,
    canBeSubmitted,
    metaData,
    pending,
    onHandle,
    onSubmit,
  } = useContext(FormContent);

  useEffect(() => {
    if (canBeSubmitted) {
      onSubmit({ type, data, metaData });
    }
  }, [canBeSubmitted, data, metaData, onSubmit, type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onHandle();
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Heading3 className={styles.title}>{t(title)}</Heading3>
        <div className={styles.registration}>
          {info}
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles["input-group"]}>
              {fields.map((input) => {
                return <InputItem key={input.id} {...input} />;
              })}
              <Action className={styles.submit} disabled={pending}>
                <Paragraph color="brand" appearance="bold">
                  {t(buttonText)}
                </Paragraph>
                <span className={styles.arrow}>
                  <Icon className={styles.icon} name="arrow_right" />
                </span>
              </Action>
            </div>
          </form>
        </div>
      </div>
      <FormSubmitStatus />
    </>
  );
};
