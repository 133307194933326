import { useTranslation } from "features/translations";
import { useContext, useEffect } from "react";
import {
  Action,
  Icon,
  InputType as InputItem,
  Paragraph,
} from "shared/components";

import { FormContent } from "../Form.Provider";
import { FormSubmitStatus } from "../FormSubmitStatus";
import styles from "./FormApplication.module.scss";

export const FormApplication = () => {
  const { t } = useTranslation();
  const {
    data,
    type,
    buttonText,
    fields,
    canBeSubmitted,
    metaData,
    pending,
    onHandle,
    onSubmit,
  } = useContext(FormContent);

  useEffect(() => {
    if (canBeSubmitted) {
      onSubmit({ type, data, metaData });
    }
  }, [canBeSubmitted, data, metaData, onSubmit, type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onHandle();
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.application}>
          <form onSubmit={handleSubmit}>
            <div className={styles.form}>
              <div className={styles["input-group"]}>
                {fields.map((input) => {
                  return <InputItem key={input.id} {...input} />;
                })}
                <Action className={styles.submit} disabled={pending}>
                  <Paragraph color="brand" appearance="bold">
                    {t(buttonText)}
                  </Paragraph>
                  <span className={styles.arrow}>
                    <Icon className={styles.icon} name="arrow_right" />
                  </span>
                </Action>
              </div>
            </div>
          </form>
        </div>
      </div>
      <FormSubmitStatus />
    </>
  );
};
